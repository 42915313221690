<template >
  <div class="booking">
    <div class="bookinginfo container">
        <h1 class="text-center pt-3">{{getInfo.h1}}</h1>
        <div class="pt-5">
          <script  type="application/javascript" data-form-id="5d948f695eb84" src="https://secured.sirvoy.com/widget/sirvoy.js" data-widget="review"></script>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    computed:{
        getInfo(){
            return this.$store.state.language.bookinginfo
        }
    }

}
</script>

<style scoperd>


.booking{
  background-color: #094b25;
}
.bookinginfo{
  color:#2D4159;
  font-family: 'Oswald', sans-serif;
}

.bookinginfo, h2{
  text-align: center;
  font-family: 'Oswald', sans-serif;
  font-size: 2.60rem;
  color: #faf7ba;
}

@media only screen and (max-width: 600px) {
   h1 {
    font-size: 1.20rem;
  }

}
@media only screen and (min-width: 600px) {
 h1{
    font-size: 1.50rem;
  }

}
@media only screen and (min-width: 768px) {
    h1{
      font-size: 2rem;
     }

}
@media only screen and (min-width: 992px) {
     h1{
     font-size: 2.50rem;
    }
}
</style>