<template>
  <div id="gallery">
      <div class="container pb-5 px-0">
        <h4 class="text-sm-left pr-5 pt-3">{{getGallery.house}} A B E F</h4>
        <div id="c-1" class="carousel slide mb-5 carousel-container shadow-lg rounded" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="../assets/abef-hus/abed01.jpg" class="w-100 " alt="">
                </div>
                <div class="carousel-item">
                    <img src="../assets/abef-hus/abed02.jpg" class="w-100 " alt="">
                </div>
                <div class="carousel-item">
                    <img src="../assets/abef-hus/abed03.jpg" class="w-100 " alt="">
                </div>
                <div class="carousel-item">
                    <img src="../assets/abef-hus/abed04.jpg" class="w-100 " alt="">
                </div>
                 <div class="carousel-item">
                    <img src="../assets/abef-hus/abed05.jpg" class="w-100 " alt="">
                </div>
                 <div class="carousel-item">
                    <img src="../assets/abef-hus/abed06.jpg" class="w-100 " alt="">
                </div>
                 <div class="carousel-item">
                    <img src="../assets/abef-hus/abed07.jpg" class="w-100 " alt="">
                </div>
                 <div class="carousel-item">
                    <img src="../assets/abef-hus/abed08.jpg" class="w-100 " alt="">
                </div>
                 <div class="carousel-item">
                    <img src="../assets/abef-hus/abed09.jpg" class="w-100 " alt="">
                </div>

                 <div class="carousel-item">
                    <img src="../assets/abef-hus/abed11.jpg" class="w-100 " alt="">
                </div>
                 <div class="carousel-item">
                    <img src="../assets/abef-hus/abed12.jpg" class="w-100 " alt="">
                </div>
                 <div class="carousel-item">
                    <img src="../assets/abef-hus/abed13.jpg" class="w-100 " alt="">
                </div>
            </div>
            <a class="carousel-control-prev" href="#c-1" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            </a>
            <a class="carousel-control-next" href="#c-1" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
            </a>
        </div>


        <h4 class="text-sm-left pr-5">{{getGallery.house}} C & D</h4>
        <div id="c-2" class="carousel slide mb-5 carousel-container shadow-lg rounded" data-ride="carousel">
            <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="../assets/cd-hus/cd01.jpg" class="w-100 " alt="">
                </div>
                <div class="carousel-item">
                      <img src="../assets/cd-hus/cd02.jpg" class="w-100 " alt="">
                </div>
                <div class="carousel-item">
                      <img src="../assets/cd-hus/cd03.jpg" class="w-100 " alt="">
                </div>
                <div class="carousel-item">
                      <img src="../assets/cd-hus/cd04.jpg" class="w-100 " alt="">
                </div>
                 <div class="carousel-item">
                      <img src="../assets/cd-hus/cd05.jpg" class="w-100 " alt="">
                </div>
                 <div class="carousel-item">
                      <img src="../assets/cd-hus/cd06.jpg" class="w-100 " alt="">
                </div>
                 <div class="carousel-item">
                      <img src="../assets/cd-hus/cd07.jpg" class="w-100 " alt="">
                </div>
                 <div class="carousel-item">
                      <img src="../assets/cd-hus/cd08.jpg" class="w-100 " alt="">
                </div>
                <div class="carousel-item">
                    <img src="../assets/cd-hus/cd13.jpg" class="w-100 " alt="">
                </div>
                <div class="carousel-item">
                    <img src="../assets/cd-hus/cd14.jpg" class="w-100 " alt="">
                </div>
                <div class="carousel-item">
                    <img src="../assets/cd-hus/cd16.jpg" class="w-100 " alt="">
                </div>
                <div class="carousel-item">
                    <img src="../assets/cd-hus/cd17.jpg" class="w-100 " alt="">
                </div>
                <div class="carousel-item">
                    <img src="../assets/cd-hus/cd18.jpg" class="w-100 " alt="">
                </div>
                <div class="carousel-item">
                    <img src="../assets/cd-hus/cd19.jpg" class="w-100 " alt="">
                </div>
                <div class="carousel-item">
                    <img src="../assets/cd-hus/cd20.jpg" class="w-100 " alt="">
                </div>
                <div class="carousel-item">
                    <img src="../assets/cd-hus/cd21.jpg" class="w-100 " alt="">
                </div>
            </div>
            <a class="carousel-control-prev" href="#c-2" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            </a>
            <a class="carousel-control-next" href="#c-2" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
            </a>
        </div>


         <h4 class="text-sm-left pr-5">{{getGallery.apartment}}</h4>
        <div id="apartment" class="carousel slide mb-5 carousel-container shadow-lg rounded" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="../assets/apartment/IMG_0038.jpg" class="d-block w-100" alt="">
                </div>
                <div class="carousel-item">
                    <img src="../assets/apartment/IMG_0026.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                    <img src="../assets/apartment/IMG_0027.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                    <img src="../assets/apartment/IMG_0028.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                    <img src="../assets/apartment/IMG_0029.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                       <img src="../assets/apartment/IMG_0030.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                    <img src="../assets/apartment/IMG_0031.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                    <img src="../assets/apartment/IMG_0032.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                    <img src="../assets/apartment/IMG_0033.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                    <img src="../assets/apartment/IMG_0034.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                    <img src="../assets/apartment/IMG_0035.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                    <img src="../assets/apartment/IMG_0036.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                      <img src="../assets/apartment/IMG_0037.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                    <img src="../assets/apartment/IMG_0038.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                     <img src="../assets/apartment/IMG_0039.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                    <img src="../assets/apartment/IMG_0040.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                    <img src="../assets/apartment/IMG_0041.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                    <img src="../assets/apartment/IMG_0042.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                    <img src="../assets/apartment/IMG_0043.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                    <img src="../assets/apartment/IMG_0044.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                     <img src="../assets/apartment/IMG_0045.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                   <img src="../assets/apartment/IMG_0046.jpg" class="d-block w-100" alt="">        
                </div>
                <div class="carousel-item">
                    <img src="../assets/apartment/IMG_0047.jpg" class="d-block w-100" alt="">        
                </div>
            </div>
            <a class="carousel-control-prev" href="#apartment" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            </a>
            <a class="carousel-control-next" href="#apartment" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
            </a>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    computed:{
        getGallery(){
            return this.$store.state.language.gallery
        }
    }

}
</script>

<style scoped>
 /* yellow #faf7ba
    green #094b25
    blue #1b68b3
    balck #030404
  */
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

#gallery{
    background:#094b25;
} 
.carousel-container{
    border: 5px solid #0b612d;
}

#gallery img{
    /* height: 700px !important; */
}
#gallery h4 {
    text-align: center;
    color:#faf7ba;
    font-family: 'Oswald', sans-serif;
    font-size: 2.20rem;
} 

</style>